import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "components/UI/Modal";
import Trash from "components/UI/Icons/Trash";
import View from "components/UI/Icons/View";
import Open from "components/UI/Icons/Open";
import ArrowDown from "components/UI/Icons/ArrowDown";
import HeaderDropDown from "components/UI/HeaderDropDown";
import { default as PageLayout } from "components/UI/Elements/Page";
import Button from "components/UI/Buttons/Button";
import { DocumentForm } from "../../pages/profile/Components/DocumentForm";
import Select from "react-select";
import { FaFilePdf } from "react-icons/fa";
import { UseOrganizationContext, UseErrorContext } from "contexts";
import { getAdminOrganization, getOrganizationDocumentContent } from "api/services/organization";
import { pdfjs, Document, Page } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const FileContainer = styled.div`
  padding: 1.2rem 2.4rem;
  border: 1px solid grey;
  border-radius: 2px;
  display: flex;
  gap: 1.2rem;
  position: relative;
  cursor: pointer;
  min-width: 16rem;
  .file {
    &-icon {
      width: 3.2rem;
      height: 3.2rem;
      .lang{
        font-size: 1.1rem;
        margin-left: .6rem
      }
    }
    &-meta {
    }
    &-title {
      margin-bottom: 0.2rem;
      margin-top: 2rem;
    }
    &-date {
      font-size: 12px;
      opacity: 0.6;
    }
  }

  .linked-action {
    max-width: 8rem;
    width: 8rem;
    span {
      color: lightgrey;
      font-size: 10px;
      position: absolute;
    }
    .action-title {
      background-color: #d3d4fdff;
      color: black;
      font-size: 12px;
      padding: 0.4rem;
      opacity: 0.7;
      border-radius: 0.4rem;
      margin-top: 1.2rem;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    position: absolute;
    top: 2rem;
    right: 0.8rem;
  }
`;

function truncateString(str) {
  const maxLength = 20;
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...';
  } else {
    return str;
  }
}

const LibItem = ({ item, open, index, showDeleteModal, downloadFile, isReadOnly }) => {
  console.log("key", open);
  return (
    <FileContainer>
      <div>
        <div className="file-icon">
          <FaFilePdf color="" size={28} />
          <span className="lang">{item.languageCode || 'EN'}</span>
        </div>
        <div className="buttons">
          {!isReadOnly && (
            <div onClick={() => showDeleteModal(document)} className="icon-button">
              <Trash />
            </div>
          )}
          {!isReadOnly && (
            <div onClick={() => open(item)} className="icon-button">
              <View />
            </div>
          )}
          {!isReadOnly && (
            <div onClick={() => downloadFile(item)} className="icon-button">
              <ArrowDown />
            </div>
          )}
        </div>
        <div className="file-meta">
          <div className="file-title">{truncateString(item?.fileName)}</div>
          <div style={{ display: "none" }} className="file-date">
            Date: 1/1/2024
          </div>
        </div>
      </div>
      {index % 2 == 38 && (
        <div className="linked-action">
          <span>Linked to action</span>
          <div className="action-title">Q1 Financial Report</div>
        </div>
      )}
    </FileContainer>
  );
};

const Container = styled.div`
  overflow: auto;

  .react-pdf__Page__textContent,
  .react-pdf__Page__annotations {
    display: none;
  }

  .react-pdf__Page {
    min-height: 815px !important;
  }

  .pdf-buttons {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }

  .filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  }

  .filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2.4rem;
  }

  .library-container {
    margin-top: 4rem;
  }

  .category-container {
    margin-bottom: 1rem;
    .category-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        font-size: 12px;
      }
    }

    h2 {
      margin-bottom: 1.2rem;
    }
  }

  .lib-items-hor {
    display: flex;
    gap: 1.2rem;
    max-width: 100%;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    flex-wrap: wrap;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 7px;
      background: initial;
    }
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.4);
    }
  }
`;

const options = [
  { value: undefined, label: "All categories" },
  { value: "Press release", label: "Press release" },
  { value: "Presentations", label: "Presentations" },
  { value: "Annual reports", label: "Annual reports" },
];

const langs = [
  { value: undefined, label: "All languages" },
  { value: "EN", label: "EN" },
  { value: "NL", label: "NL" },
  { value: "FR", label: "FR" },
];

const Library = ({ isReadOnly }) => {
  const [categorySelect, setCategorySelect] = useState();
  const [langSelect, setLangSelect] = useState();
  const [listView, setListView] = useState(true);
  const [documents, setDocuments] = useState([]);
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showModalPreviewDoc, setShowModalPreviewDoc] = useState(false);
  const [docData, setDocData] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const _addDocuments = () => {
    //Clear selected document
    setSelectedDocument(null);
    setShowModal(true);
  };

  const showDeleteModal = async (document) => {
    setSelectedDocument(document);
    setShowModalDelete(true);
  };

  const _delete = async () => {
    await deleteOrganizationDocument(organizationId, selectedDocument.id);
    setShowModalDelete(false);
    setSelectedDocument(null);
    refresh();
  };

  useEffect(async () => {
    refresh();
  }, [organizationId]);

  const refresh = async () => {
    try {
      if (organizationId) {
        let organizationData = await getAdminOrganization(organizationId);

        var documents = organizationData.documents;
        setDocuments(documents);
      }
    } catch (err) {
      updateError(err);
    }
  };

  const PreviewDocument = async (document) => {
    console.log("doc", document);
    setSelectedDocument(document);
    setShowModalPreviewDoc(true);
    const docDataResp = await getOrganizationDocumentContent(organizationId, document.id, document.fileName, false);
    console.log("docDataResp", docDataResp);
    setDocData(docDataResp);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const downloadFile = async (document) => {
    return await getOrganizationDocumentContent(organizationId, document.id, document.fileName);
  };

  const open = (document) => {
    console.log("test");
    setSelectedDocument(document);
    setShowModal(true);
  };

  return (
    <PageLayout>
      <div className="page__inner">
        <HeaderDropDown titleHeader="Library">
          <Container>
            {showModalDelete && (
              <Modal title={`Delete "${selectedDocument.name}"`} onClose={() => setShowModalDelete(false)} onSubmit={() => _delete()} cancelLabel={"Cancel"} submitLabel={"Delete"}>
                <div>Are you sure you want to delete this document?</div>
              </Modal>
            )}
            {showModal && (
              <Modal title="Document" hideActions>
                <DocumentForm
                  document={selectedDocument}
                  onClose={() => {
                    setShowModal(false);
                    refresh();
                  }}
                />
              </Modal>
            )}
            {showModalPreviewDoc && (
              <Modal wide title="Preview document" hideActions showClose showCloseOnTop onClose={() => setShowModalPreviewDoc(false)}>
                <div className="">
                  {selectedDocument && (
                    <>
                      <Document file={docData} onLoadSuccess={onDocumentLoadSuccess}>
                        <div className="pdf-buttons">
                          <Button label="Previous" disabled={pageNumber <= 1} onClick={previousPage} type="secondary" />
                          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                          <Button label="Next" disabled={pageNumber >= numPages} onClick={nextPage} type="secondary" />
                        </div>
                        <Page pageNumber={pageNumber} />
                        <div className="pdf-buttons">
                          <Button label="Previous" disabled={pageNumber <= 1} onClick={previousPage} type="secondary" />
                          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                          <Button label="Next" disabled={pageNumber >= numPages} onClick={nextPage} type="secondary" />
                        </div>
                      </Document>
                    </>
                  )}
                </div>
              </Modal>
            )}

            <div className="filter-container">
              <div className="filters">
                <div>Total files ({documents?.length})</div>
                <input placeholder="Search..." />
                <Select
                  defaultValue={options[0]}
                  className="react-selectcomponent"
                  options={options}
                  onChange={(e) => {
                    setCategorySelect(e.value);
                  }}
                />
                <Select
                  defaultValue={langs[0]}
                  className="react-selectcomponent"
                  options={langs}
                  onChange={(e) => {
                    setLangSelect(e.value);
                  }}
                />
              </div>
              <div className="filters">
                <Button label={"Upload file(s)"} onClick={() => _addDocuments()} />
              </div>
            </div>

            {listView ? (
              <>
                <div className="library-container">
                  <div className="category-container">
                    <div className="category-header">
                      <h2>Documents</h2>
                    </div>
                    <div className="lib-items-hor">
                      {documents.map((i, index) => {
                        return <LibItem item={i} open={(doc) => PreviewDocument(doc)} showDeleteModal={(doc) => showDeleteModal(doc)} downloadFile={(doc) => downloadFile(doc)} index={index} />;
                      })}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="library-container">
                  <div className="category-container">
                    <div className="category-header">
                      <h2>Annual Reports</h2>
                    </div>
                    <div className="lib-items-hor">
                      {[...new Array(5)].map((i, index) => {
                        return <LibItem item={i} index={index} />;
                      })}
                    </div>
                  </div>

                  <div className="category-container">
                    <h2>Press release</h2>
                    <div className="lib-items-hor">
                      {[...new Array(5)].map((i, k) => {
                        return <LibItem item={i} key={k} />;
                      })}
                    </div>
                  </div>

                  <div className="category-container">
                    <h2>Presentation</h2>
                    <div className="lib-items-hor">
                      {[...new Array(5)].map((i, k) => {
                        return <LibItem item={i} key={k} />;
                      })}
                    </div>
                  </div>
                  <div className="category-container">
                    <h2>Annual Reports</h2>
                    <div className="lib-items-hor">
                      {[...new Array(5)].map((i, index) => {
                        return <LibItem item={i} index={index} />;
                      })}
                    </div>
                  </div>

                  <div className="category-container">
                    <h2>Press release</h2>
                    <div className="lib-items-hor">
                      {[...new Array(5)].map((i, k) => {
                        return <LibItem item={i} key={k} />;
                      })}
                    </div>
                  </div>

                  <div className="category-container">
                    <h2>Presentation</h2>
                    <div className="lib-items-hor">
                      {[...new Array(5)].map((i, k) => {
                        return <LibItem item={i} key={k} />;
                      })}
                    </div>
                  </div>
                </div>
              </>
            )}
          </Container>
        </HeaderDropDown>
      </div>
    </PageLayout>
  );
};

export default Library;
