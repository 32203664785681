import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormatDate } from "api/helpers/datahelper";

const Container = styled.div`
  overflow: auto;
  table {
    width: 100%;
    min-width: 100rem;
  }
`;

const ShareholdersGrid = ({ shareholders}) => {
  const [showAllFields, setShowAllFields] = useState(false);

  return (
    <Container>
      <div style={{ marginBottom: "2rem" }}>
        <label>Show all fields</label>
        <input type={"checkbox"} value={showAllFields} onChange={() => setShowAllFields(!showAllFields)} />
      </div>
      <table className="table" cellSpacing="500" style={{ minWidth: showAllFields ? "180rem" : "110rem" }}>
        <thead>
          <tr>            
            <th></th>
            <th>Country</th>
            <th>Account holder type</th>
            <th>Name</th>
            <th>Product</th>
            <th>Bank</th>
            {/* <th>Number of shares</th> this number is currently not correct in the excel received from Euroclear*/}
            {
              showAllFields && 
              <>
                <th>Email</th>
                <th>Registered</th>
              </>
            }
           </tr>
        </thead>
        <tbody>
          {shareholders.map((shareholder, idx) => (
            <tr key={shareholder.id + '_' + idx}>
              <td></td>
              <td>{shareholder.country}</td>
              <td>{shareholder.accountHolderType}</td>
              <td>{shareholder.accountHolderType == "NaturalPerson" ? 
                      shareholder.naturalPersonFirstName + " " + shareholder.naturalPersonSurname 
                      : shareholder.legalPersonName}</td>
              <td>{shareholder.product}</td>
              <td>{shareholder.bank}</td>
              {/* <td>{shareholder.quantity}</td> */}
              {
                showAllFields && 
                <>
                <td>{shareholder.email}</td>             
                <td>{shareholder.hasRegistered ? "Yes" : "No"}</td>
                </>
              }
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

ShareholdersGrid.defaultProps = {
  shareholders: Array.from(Array(0)),
};

export default ShareholdersGrid;
