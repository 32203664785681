import { getAxiosClient } from "../axios";

export const translateContent = async (content, sourceLang, targetLang) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .post("/translations", { Text: content, SourceLang: sourceLang, TargetLang: targetLang })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      alert(error?.response?.data?.title);
      return Promise.reject(error);
    });
};

export const getPossibleLanguages = async () => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get("/languages")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      alert(error?.response?.data?.title);
      return Promise.reject(error);
    });
};

export const updateOrganizationLanguages = async (id, languages) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .put(`/organizations/${id}/languages`, [...languages])
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      alert(error?.response?.data?.title);
      return Promise.reject(error);
    });
};

export const getOrganizationLanguages = async (id) => {
  const axiosClient = await getAxiosClient();

  return await axiosClient
    .get(`/admin/organizations/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      alert(error?.response?.data?.title);
      return Promise.reject(error);
    });
};
