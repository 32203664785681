import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HeaderDropDown from "../../components/UI/HeaderDropDown";
import PageHeaderSub from "../../components/PageHeaderSub";
import Page from "../../components/UI/Elements/Page";
import { PieChart, Pie, Legend, Cell, Tooltip, BarChart, CartesianGrid, XAxis, YAxis, Bar } from "recharts";
import { UseOrganizationContext, UseErrorContext } from "contexts";
import { getAnalytics } from "../../api/services/investor-insights";
import AnalyticsBlock from "./components/AnalyticsBlock";
import ShareholderChanges from "./components/ShareholderChanges";
import moment from "moment";

const StyledTooltip = styled.div`
  background-color: hsla(0, 0%, 100%, 0.8);
  border: 1px solid #f5f5f5;
  line-height: 24px;
  margin: 0;
  padding: 10px;
  width: 250px;

  .label {
    color: #666;
    font-weight: 700;
    margin: 0;
  }

  .desc {
    color: #999;
    margin: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  .item {
    padding: 1rem;
    margin: 1rem;
    width: calc(96% / 2);
    background-color: var(--color-black);
  }
  .card {
    &__title {
      color: var(--color-text);
      text-decoration: underline;
    }
    &__content {
      border-top: 0px;
    }
    svg {
      tspan {
        fill: white;
      }
      line {
        stroke: white;
      }
    }
  }

  .recharts-pie-label-text {
    content: "%";
    fill: red;
    &:after {
      content: "%";
    }
  }
  .fluctuations-checkbox {
    color: white;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
`;

const Analytics = () => {
  const organizationId = UseOrganizationContext()?.id;
  const { updateError } = UseErrorContext();
  const [entityData, setEntityData] = useState([]);
  const [barChartPerCountry, setBarChartPerCountry] = useState([]);
  const [barChartPerRequest, setBarChartPerRequest] = useState([]);
  const [percentActive, setPercentActive] = useState(false);

  useEffect(async () => {
    if (!organizationId) return;

    try {
      var rawData = await getAnalytics(organizationId);
      updateEntityData(rawData);
      updateCountryData(rawData);
      updateRequestData(rawData);
    } catch (err) {
      updateError(err);
    }
  }, [organizationId]);

  const updateEntityData = (rawData) => {
    const newData = rawData.requestDetails.at(-1);
    const entityData = [
      { name: "Legal entity", value: newData.legalEntity.sharesPercentage, number: newData.legalEntity.numberOfShareholders, percentage: `${newData.legalEntity.sharesPercentage}%` },
      { name: "Natural Person", value: newData.naturalPerson.sharesPercentage, number: newData.naturalPerson.numberOfShareholders, percentage: `${newData.naturalPerson.sharesPercentage}%` },
    ];
    setEntityData(entityData);
  };

  const updateCountryData = (rawData) => {
    const newData = [];

    rawData.countryDetails.forEach((country) => {
      newData.push({
        name: country.countryName,
        legal: country.legalEntity.numberOfShareholders,
        legalShares: country.legalEntity.sharesPercentage,
        natural: country.naturalPerson.numberOfShareholders,
        naturalShares: country.naturalPerson.sharesPercentage,
      });
    });

    setBarChartPerCountry(newData);
  };

  const updateRequestData = (rawData) => {
    const newData = [];

    rawData.requestDetails.forEach((request) => {
      newData.push({
        name: moment(request.requestDate).format("DD-MM"),
        legal: request.legalEntity.numberOfShareholders,
        legalShares: request.legalEntity.sharesPercentage,
        natural: request.naturalPerson.numberOfShareholders,
        naturalShares: request.naturalPerson.sharesPercentage,
      });
    });

    setBarChartPerRequest(newData);
  };

  const COLORS = ["#FF8972", "lightblue", "#FFBB28", "#FF8042"];

  const barChart = [
    { name: "1/3", legal: 1530, natural: 25391, legalShares: 3000000, naturalShares: 5000000 },
    { name: "1/4", legal: 1545, natural: 26391, legalShares: 2700000, naturalShares: 5300000 },
    { name: "1/5", legal: 1540, natural: 27391, legalShares: 2400000, naturalShares: 5600000 },
    { name: "1/6", legal: 1538, natural: 29391, legalShares: 1900000, naturalShares: 6100000 },
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      console.log(payload[0]);
      return (
        <StyledTooltip className="custom-tooltip">
          <p className="label">{`${payload[0].name} : ${payload[0].payload.percentage}`}</p>
          <p className="label">Number of holders : {payload[0].payload.number}</p>
        </StyledTooltip>
      );
    }

    return null;
  };

  return (
    <Page>
      <div className="page__header">
        <h1>ANALYTICS</h1>
        <PageHeaderSub />
      </div>
      <div className="page__inner">
        <HeaderDropDown titleHeader="Shareholder analytics">
          <Container>
            <AnalyticsBlock title="Legal Entity vs Natural Persons" className="item">
              <PieChart width={500} height={300}>
                <Pie data={entityData} dataKey="value" outerRadius={80} innerRadius={40} paddingAngle={2} fill="#8884d8" label={(data) => data.payload.value + "%"}>
                  {entityData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend />
              </PieChart>
            </AnalyticsBlock>
            <AnalyticsBlock title="Fluctuations in shareholder base" className="item">
              <div className="fluctuations-checkbox">
                <input type="checkbox" label="Show in %" value={percentActive} onChange={() => setPercentActive(!percentActive)} /> Show in %
              </div>
              <BarChart
                width={500}
                height={300}
                data={barChartPerRequest}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis yAxisId={0} />
                <YAxis
                  orientation="right"
                  yAxisId={1}
                  tickFormatter={(tick) => {
                    return `${tick}%`;
                  }}
                />
                <Tooltip />
                <Legend width={400} />
                {!percentActive ? (
                  <>
                    <Bar name="Legal entity" dataKey="legal" stackId="a" fill="#FF8972" maxBarSize={25} yAxisId={0} />
                    <Bar name="Natural person" dataKey="natural" stackId="a" fill="lightblue" maxBarSize={25} yAxisId={0} />
                  </>
                ) : (
                  <>
                    <Bar name="Legal entity %" dataKey="legalShares" stackId="b" fill="#FF8972" maxBarSize={25} yAxisId={1} />
                    <Bar name="Natural person %" dataKey="naturalShares" stackId="b" fill="lightblue" maxBarSize={25} yAxisId={1} />
                  </>
                )}
              </BarChart>
            </AnalyticsBlock>
            <AnalyticsBlock title="Number of shareholders per country" className="item">
              <BarChart
                width={500}
                height={300}
                data={barChartPerCountry}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar name="Legal entity" dataKey="legal" stackId="a" fill="#FF8972" maxBarSize={25} />
                <Bar name="Natural person" dataKey="natural" stackId="b" fill="lightblue" maxBarSize={25} />
              </BarChart>
            </AnalyticsBlock>
            <AnalyticsBlock title="Shareholding per country by number of shares">
              <BarChart
                width={500}
                height={300}
                data={barChartPerCountry}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  tickFormatter={(tick) => {
                    return `${tick}%`;
                  }}
                />
                <Tooltip />
                <Legend />
                <Bar name="Legal entity" dataKey="legalShares" stackId="a" fill="#FF8972" maxBarSize={25} />
                <Bar name="Legal entity" dataKey="naturalShares" stackId="b" fill="lightblue" maxBarSize={25} />
              </BarChart>
            </AnalyticsBlock>
          </Container>
        </HeaderDropDown>

        <HeaderDropDown bodyStyle={{ position: "relative" }} titleHeader={"Shareholder trends"} id="shareholder-trends">
          <ShareholderChanges scrollToID={"shareholder-trends"} />
        </HeaderDropDown>
      </div>
    </Page>
  );
};

export default Analytics;
